<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard | Marketing</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Schedule</a></li>
    </ol>
    <h4 class="page-header">CHANNEL SCHEDULES</h4>
    <panel variant="panel-default">
      <vue-good-table :columns="columns" :lineNumbers="true" :pagination-options="{ enabled: true, position: 'bottom' }" :rows="schedules" :search-options="{ enabled: true }">
        <div slot="table-actions">
          <button class="btn btn-sm btn-black" @click.prevent="settingModal(null)">
            <i class="fa fa-plus"></i> Create New
          </button>
          <download-excel v-if="schedules.length" :data="schedules" :title="'Marketing Schedules'" class="btn btn-primary" name="Schedules.xls">
            <i class="fa fa-file-excel"></i> Export Excel
          </download-excel>
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.label === 'Action'">
            <button class="btn btn-sm btn-info" type="button" @click="settingModal(props.row)">Edit</button>
          </span>
        </template>
      </vue-good-table>
    </panel>
    <modal :show-modal="showModal" @close="showModal = false">
      <template v-slot:header>
        <h5>
          <span v-if="schedule">Edit </span><span v-else>Create</span> Schedule
        </h5>
      </template>
      <form @submit.prevent="save">
        <div v-html="$error.handle(error)" />
        <div class="row">
          <div class="form-group col-md-12">
            <label>Name</label>
            <input v-model="name" class="form-control" required type="text">
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <label>Start Date</label><input ref="yesterdays_date" v-model="maxStartDate" type="hidden">
            <date-picker v-model="start_date" v-validate="'required|date_format:yyyy-MM-dd|after:yesterdays_date'" format="yyyy-MM-dd" name="start_date" input-class="form-control bg-white"/>
            <small v-if="errors.has('start_date')" class="text-danger">
              {{ errors.first('start_date') }}
            </small>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <label>Template</label>
            <v-select :options="sms_templates" :reduce="x => x.id" label="name" placeholder="Templates"/>
          </div>
          <div class="form-group col-md-6">
            <label>Group</label>
            <v-select :options="groups" :reduce="x => x.id" label="name" placeholder="Groups"/>
          </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
              <label>Status</label>
              <v-select :options="status" :reduce="x => x.value" label="name" placeholder="Status"/>
            </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <button class="btn btn-primary w-100">Save</button>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker';
import moment from 'moment/src/moment';
export default {
  components: {
    DatePicker
  },
  data() {
    return {
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Template",
          field: "template",
        },
        {
          label: "Group",
          field: "group",
        },
        {
          label: "Date",
          field: "date",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Action",
          field: "id",
        }
      ],
      loading: true,
      showModal: false,
      schedule: null,
      name: '',
      start_date:'',
      maxStartDate: moment().add(-1, 'day').format('yyyy-MM-DD'),
      disabledDates: {
        to: moment().add(-1, 'day').toDate()
      },
      error: '',
      schedules: [],
      groups: [],
      status:[{value:1,name:'Active'},{value:0,name:'Inactive'}],
      sms_templates: [],
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$axios.get('/api/v1/schedule-settings').then(response => {
        let glist = response.data.data;
        this.schedules = glist.map(x => {
          return {'id': x.id, 'name': x.name}
        });
      });
      this.$axios.get('/api/v1/marketing-group').then(response => {
        let glist = response.data.data;
        this.groups = glist.map(x => {
          return {'id': x.id, 'name': x.name}
        });
      });
      this.$axios.get('/api/v1/sms-templates').then(response => {
        let glist = response.data.data;
        this.sms_templates = glist.map(x => {
          return {'id': x.id, 'name': x.name}
        });
      });
    },
    save() {
      if (this.schedule) {
        this.edit();
        return;
      }
      this.$axios.post('/api/v1/schedule-settings', {'name': this.name}).then(() => {
        this.showModal = false;
        this.fetch();
        this.name = '';
        this.schedule = null;
        this.$toastr.s('Schedule created successfully!');
      });
    },
    edit() {
      this.$axios.put('/api/v1/schedule-settings/' + this.schedule.id, {'name': this.name}).then(() => {
        this.showModal = false;
        this.name = '';
        this.schedule = null;
        const index = this.schedules.findIndex(d => d.id === this.schedule.id);
        if (index !== -1) this.schedules[index] = this.schedule;
        this.$toastr.s("Schedules updated successfully")
      }).catch(error => {
        this.error = error.response;
      });
    },
    settingModal(setting) {
      if (setting != null) {
        this.schedule = setting;
        this.name = setting.name;
      } else {
        this.schedule = null;
      }
      this.showModal = true;
    }
  }
}
</script>